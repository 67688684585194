import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useContext, useState } from 'react';
import { Radio, RadioChangeEvent } from 'antd';
import styled from 'styled-components';
import { useFormik } from 'formik';
import diff from 'microdiff';
import _ from 'lodash';
import Joyride, { Step } from 'react-joyride';
import { ToastContext } from '../../../contexts/Toast';
import {
	useMakeVehiclePlateMutation,
	useUpdateVehiclePlateMutation,
} from '../../../store/apis/vehiclePlate';
import useCheckRole from '../../../hooks/useCheckRole';
import {
	MakeVehiclePlateDto,
	UpdateVehiclePlateDto,
	VehiclePlateDto,
} from '../../../interface/vehiclePlate';
import { fullLoadingOff, fullLoadingOn } from '../../../store/webUtil';
import { CustomErrorType } from '../../../store/apis/@types';
import {
	PLATES_CREATE_PATH,
	PLATES_MANAGEMENT_PATH,
	VEHICLES_DETAIL_PATH,
} from '../../../routes/constants/urls';
import GridDetailTemplate from '../GridDetailTemplate';
import Button from '../../atoms/Button';
import theme, { ButtonTypeType } from '../../../styles/theme';
import ContentBoxWithHeader from '../../molecules/ContentBoxWithHeader';
import InputForm from '../../molecules/InputForm';
import Label from '../../atoms/Label';
import Typo from '../../atoms/Typo';
import Input from '../../atoms/Input';
import InputAlert from '../../atoms/InputAlert';
import Alert from '../../atoms/Alert';
import GridLayout, { GridItem } from '../../molecules/GridLayout';
import SelectionModal from '../../organisms/SelectionModal';
import {
	useGetCompaniesQuery,
	useGetCompanyDetailQuery,
} from '../../../store/apis/company';
import { companyModalColumn } from '../modalColumn';
import TextArea from '../../atoms/TextArea';
import { RootState } from '../../../store';
import markTutorialCompleted from '../../../utils/tutorial-utils';
import useTutorialGuide from '../../../hooks/useTutorialGuide';

interface VehiclePlateTemplateProps {
	initialValues: MakeVehiclePlateDto | UpdateVehiclePlateDto;
	extraInfo?: VehiclePlateDto;
}

const enum AlertState {
	NONE,
	CREATE,
	UPDATE,
}

const InnerContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
`;

const ButtonContainer = styled.div`
	display: flex;
	gap: 8px;
`;

const VehiclePlateTemplate = ({
	initialValues,
	extraInfo,
}: VehiclePlateTemplateProps) => {
	const { user } = useSelector((state: RootState) => state.auth);
	const userId = user?.id;
	const { plateNumber } = useParams();
	const tutorialId = 'vehiclePlateDetail';
	const location = useLocation();

	const isVehiclePlateCreatePage = location.pathname === PLATES_CREATE_PATH;

	const handleTutorialCompletion = () => {
		markTutorialCompleted(userId!, tutorialId); // Pass userId and tutorialKey
	};

	const steps: Step[] = [
		{
			content: (
				<div style={{ textAlign: 'left', padding: '1rem' }}>
					<h3 style={{ fontSize: '1.2rem', marginBottom: '10px' }}>
						번호판 관리 페이지입니다.
					</h3>

					<p style={{ fontSize: '0.9rem', lineHeight: '1.6' }}>
						법인이 소유하고 있는 번호판을 둥록하거나 수정할 수 있습니다.
					</p>
				</div>
			),
			locale: {
				skip: (
					<strong
						style={{
							padding: '8px 16px',
							backgroundColor: theme.common.colors.gray_12,
							color: '#fff',
							borderRadius: '4px',
							cursor: 'pointer',
						}}
						aria-label="skip"
						onClick={handleTutorialCompletion}
					>
						건너뛰기
					</strong>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'center',
			target: 'body',
		},
		{
			title: '번호판 넘버 입력',
			content: (
				<div>
					<span style={{ color: 'red' }}>(필수) </span>
					번호판 넘버를 입력해주세요.
				</div>
			),
			locale: {
				skip: (
					<strong
						style={{
							padding: '8px 16px',
							backgroundColor: theme.common.colors.gray_12,
							color: '#fff',
							borderRadius: '4px',
							cursor: 'pointer',
						}}
						aria-label="skip"
						onClick={handleTutorialCompletion}
					>
						건너뛰기
					</strong>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'left-start',
			target: '#step-1',
		},
		{
			title: '번호판 유형 선택',
			content: (
				<div>
					<span style={{ color: 'red' }}>(필수) </span>
					번호판 유형을 선택해주세요.
				</div>
			),
			locale: {
				skip: (
					<strong
						style={{
							padding: '8px 16px',
							backgroundColor: theme.common.colors.gray_12,
							color: '#fff',
							borderRadius: '4px',
							cursor: 'pointer',
						}}
						aria-label="skip"
						onClick={handleTutorialCompletion}
					>
						건너뛰기
					</strong>
				),
				back: '뒤로',
				next: '다음',
			},
			placement: 'left-end',
			target: '#step-2',
		},
		{
			title: '소유자 배정',
			content: (
				<div>
					<span style={{ color: 'red' }}>(필수) </span>
					소유자를 배정해주세요. 등록된 법인 중에 선택할 수 있습니다.
				</div>
			),
			locale: {
				last: '닫기',
				back: '뒤로',
			},
			placement: 'right-end',
			target: '#step-3',
		},
	];

	const { handleJoyrideCallback, localStorageDetail } = useTutorialGuide(
		userId!,
		tutorialId,
		steps,
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const toast = useContext(ToastContext);

	const [alertState, setAlertState] = useState<AlertState>(AlertState.NONE);
	const [isTrySubmit, setIsTrySubmit] = useState(false);
	const [isCompanyModal, setIsCompanyModal] = useState(false);
	const [makeVehiclePlate] = useMakeVehiclePlateMutation();
	const [updateVehiclePlate] = useUpdateVehiclePlateMutation();

	const isCreateVehiclePlate = useCheckRole({
		roleType: 'isCreate',
		roleName: '번호판관리',
	});
	const isUpdateVehiclePlate = useCheckRole({
		roleType: 'isUpdate',
		roleName: '번호판관리',
	});
	const isSuperVehiclePlate = useCheckRole({
		roleType: 'isSuper',
		roleName: '번호판관리',
	});

	const handleValidate = (values: typeof initialValues) => {
		setIsTrySubmit(true);

		const errors: Partial<Record<keyof typeof initialValues, string>> = {};

		if (values.vehicleNumber === '') {
			errors.vehicleNumber = '번호판 넘버를 입력해주세요.';
		}
		return errors;
	};

	const handleCreateSubmit = async (values: typeof initialValues) => {
		const result = await makeVehiclePlate(values as MakeVehiclePlateDto);

		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '번호판 등록이 완료되었습니다.');
			navigate(PLATES_MANAGEMENT_PATH);
		}
	};

	const handleUpdateSubmit = async (values: typeof initialValues) => {
		const diffValues = diff(initialValues, values);
		const updateValues = {};
		diffValues.map((item) => {
			if (item.type === 'CREATE' || item.type === 'CHANGE') {
				_.set(updateValues, item.path.join('.'), item.value);
			}
			return null;
		});

		const result = await updateVehiclePlate({
			body: updateValues as UpdateVehiclePlateDto,
			plateNumber,
		});

		if ('error' in result) {
			toast(
				'error',
				(result.error as CustomErrorType).data.translate ||
					(result.error as CustomErrorType).data.message,
			);
		} else {
			toast('info', '번호판 수정이 완료되었습니다.');
			navigate(PLATES_MANAGEMENT_PATH);
		}
	};

	const formik = useFormik({
		initialValues,
		onSubmit: async (values) => {
			dispatch(fullLoadingOn());
			if (!plateNumber) {
				await handleCreateSubmit(values);
			} else {
				await handleUpdateSubmit(values);
			}

			dispatch(fullLoadingOff());
		},
		validate: handleValidate,
		validateOnChange: isTrySubmit,
	});

	const handleAlertCloseClick = () => {
		setAlertState(AlertState.NONE);
	};

	const handleCancelClick = () => {
		navigate(-1);
	};

	const handleCreateClick = async () => {
		const errorObject = await formik.validateForm(formik.values);

		const isValid = Object.keys(errorObject).length === 0;

		if (isValid) {
			setAlertState(AlertState.CREATE);
		}
	};

	const handleUpdateClick = async () => {
		const errorObject = await formik.validateForm(formik.values);

		const isValid = Object.keys(errorObject).length === 0;

		if (isValid) {
			setAlertState(AlertState.UPDATE);
		}
	};

	const renderAlert = useCallback(() => {
		switch (alertState) {
			case AlertState.CREATE:
				return (
					<Alert
						title="번호판 등록"
						closeButtonClick={handleAlertCloseClick}
						onConfirmButtonText="등록하기"
						onConfirmButtonClick={() => formik.handleSubmit()}
						isVisible={alertState === AlertState.CREATE}
					>
						번호판을 등록하시겠습니까?
					</Alert>
				);
			case AlertState.UPDATE:
				return (
					<Alert
						title="번호판 수정"
						closeButtonClick={handleAlertCloseClick}
						onConfirmButtonText="수정하기"
						onConfirmButtonClick={() => formik.handleSubmit()}
						isVisible={alertState === AlertState.UPDATE}
					>
						<TextArea
							name="memo"
							placeholder="수정 사유를 적어주세요. (선택 입력)"
							value={(formik.values as UpdateVehiclePlateDto).memo}
							onChange={formik.handleChange}
							style={{ margin: '10px 0' }}
						/>
					</Alert>
				);
			default:
				return null;
		}
	}, [alertState, formik]);

	const { data: companyInfo } = useGetCompanyDetailQuery(
		{
			companyId: Number(formik.values.companyId),
		},
		{ skip: !formik.values.companyId },
	);

	return (
		<>
			<Joyride
				continuous
				callback={handleJoyrideCallback}
				run={isVehiclePlateCreatePage && !localStorageDetail}
				steps={steps}
				hideCloseButton
				scrollToFirstStep
				showSkipButton
				showProgress
				styles={{
					options: {
						primaryColor: theme.common.colors.primary_6_main,
					},
				}}
			/>

			<GridDetailTemplate
				detailTitle="번호판 관리"
				onBack={handleCancelClick}
				rightAccessory={
					<ButtonContainer>
						<Button
							$buttonType={ButtonTypeType.OPAQUE}
							onClick={handleCancelClick}
							size="small"
						>
							취소
						</Button>
						{!plateNumber ? (
							<Button
								onClick={handleCreateClick}
								size="small"
								disabled={!isCreateVehiclePlate}
							>
								{isCreateVehiclePlate ? '등록하기' : '등록 권한없음'}
							</Button>
						) : (
							<Button
								onClick={handleUpdateClick}
								size="small"
								disabled={!isUpdateVehiclePlate}
							>
								{isUpdateVehiclePlate ? '수정하기' : '수정 권한없음'}
							</Button>
						)}
					</ButtonContainer>
				}
			>
				<ContentBoxWithHeader
					title="번호판 정보"
					borderRadius="10px"
					className="inner-content"
				>
					<InnerContent>
						<InputForm id="step-1">
							<Label name="vehicleNumber" essential>
								<span className="label">
									<Typo $typoType="label2">번호판 넘버</Typo>
								</span>
							</Label>
							<Input
								name="vehicleNumber"
								placeholder="번호판 넘버를 입력해주세요"
								type="text"
								$inputSize="small"
								$inputRadius="small"
								value={formik.values.vehicleNumber ?? ''}
								onChange={formik.handleChange}
								onReset={() => formik.setFieldValue('vehicleNumber', '')}
							/>
							{formik.errors.vehicleNumber && (
								<InputAlert $inputStatus="error">
									{formik.errors.vehicleNumber}
								</InputAlert>
							)}
						</InputForm>
						<InputForm id="step-2">
							<Label name="vehiclePlateType" essential>
								<span className="label">
									<Typo $typoType="label2">번호판 유형</Typo>
								</span>
							</Label>
							<Radio.Group
								onChange={(e: RadioChangeEvent) => {
									formik.setFieldValue('vehiclePlateType', e.target.value);
								}}
								value={formik.values.vehiclePlateType}
							>
								<Radio value="일반">
									<Typo $typoType="label1">일반</Typo>
								</Radio>
								<Radio value="영업용">
									<Typo $typoType="label1">영업용</Typo>
								</Radio>
							</Radio.Group>
						</InputForm>
						{isSuperVehiclePlate && (
							<InputForm id="step-3">
								<Label
									name="ownerId"
									tooltip="등록된 법인을 선택할 수 있습니다. 소유자를 알 수 없는 경우에는 선택하지 않거나 소유자 삭제 버튼을 눌러주세요."
								>
									<span className="label">
										<Typo $typoType="label2">소유자</Typo>
									</span>
								</Label>
								{(isCreateVehiclePlate || isUpdateVehiclePlate) && (
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											gap: '12px',
										}}
									>
										<Button
											size="small"
											onClick={() => setIsCompanyModal(true)}
										>
											번호판 소유자 배정
										</Button>
										<p>
											<Typo $typoType="b5">
												{!!formik.values.companyId && companyInfo?.row.name
													? companyInfo?.row.name
													: '소유자를 선택해주세요'}
											</Typo>
										</p>
										{formik.values.companyId && (
											<Button
												size="small"
												$buttonType={ButtonTypeType.GHOST_DANGER}
												onClick={() => formik.setFieldValue('companyId', null)}
											>
												소유자 삭제
											</Button>
										)}
									</div>
								)}
								{formik.errors.companyId && (
									<InputAlert $inputStatus="error">
										{formik.errors.companyId}
									</InputAlert>
								)}
							</InputForm>
						)}
					</InnerContent>
				</ContentBoxWithHeader>
				{extraInfo && extraInfo.vehicle ? (
					<ContentBoxWithHeader
						title="연결된 차량정보"
						borderRadius="10px"
						rightAccessory={
							<Button
								size="small"
								$buttonType={ButtonTypeType.GHOST}
								onClick={() =>
									navigate(`${VEHICLES_DETAIL_PATH}/${extraInfo.vehicle?.id}`)
								}
							>
								상세보기
							</Button>
						}
					>
						<GridLayout $columnCount={4}>
							<GridItem title="차대번호">{extraInfo.vehicle.vin}</GridItem>
							<GridItem title="차군">
								{extraInfo.vehicle.vehicleCategory}
							</GridItem>
							<GridItem title="제조사">
								{extraInfo.vehicle.manufacturingCompany}
							</GridItem>
							<GridItem title="차종명">
								{extraInfo.vehicle.vehicleName}
							</GridItem>
							<GridItem title="적재량">
								{extraInfo.vehicle.loadingCapacity}
							</GridItem>
							<GridItem title="적재함 종류">
								{extraInfo.vehicle.carLoadingBoxType}
							</GridItem>
							<GridItem title="유종">{extraInfo.vehicle.fuelType}</GridItem>
							<GridItem title="연식">{extraInfo.vehicle.vehicleYear}</GridItem>
						</GridLayout>
					</ContentBoxWithHeader>
				) : (
					plateNumber && (
						<ContentBoxWithHeader title="연결된 차량정보" borderRadius="10px">
							없음
						</ContentBoxWithHeader>
					)
				)}
			</GridDetailTemplate>
			{renderAlert()}
			{isCompanyModal && (
				<SelectionModal
					onClose={() => setIsCompanyModal(false)}
					onChange={(e) => formik.setFieldValue('companyId', e[0].id)}
					column={companyModalColumn.colsList}
					useQuery={useGetCompaniesQuery}
					isVisible={isCompanyModal}
					title="번호판 소유자 등록"
					defaultSelectedIdList={[formik.values.companyId]}
				/>
			)}
		</>
	);
};

export default VehiclePlateTemplate;
